<template>
  <section class="mt-2">  

    <EventLog type="admin"></EventLog>

  </section>
</template>
<script>
import EventLog from '@/components/EventLog.vue';
export default {
  components: {
    EventLog
  },
  props: [],
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {   
  },
  watch: {
  },
  mounted: function() {
  }
};
</script>
<style></style>
